/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FTElementMetadata } from './FTElementMetadata';
import { FTElementMetadataFromJSON, FTElementMetadataFromJSONTyped, FTElementMetadataToJSON } from './FTElementMetadata';

/**
 *
 * @export
 * @interface FTShapeElement
 */
export interface FTShapeElement {
  /**
   *
   * @type {string}
   * @memberof FTShapeElement
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FTShapeElement
   */
  type: FTShapeElementTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FTShapeElement
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  x: number;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  y: number;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  width: number;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  height: number;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  opacity?: number;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  visible?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  selectable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  removable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  alwaysOnTop?: boolean;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  rotation?: number;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  blurEnabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  blurRadius?: number;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  brightnessEnabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  brightness?: number;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  sepiaEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  grayscaleEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  showInExport?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  shadowEnabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  shadowBlur?: number;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  shadowOffsetX?: number;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  shadowOffsetY?: number;
  /**
   *
   * @type {string}
   * @memberof FTShapeElement
   */
  shadowColor?: string;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  shadowOpacity?: number;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  draggable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  resizable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  contentEditable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  styleEditable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  flipX?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  flipY?: boolean;
  /**
   *
   * @type {string}
   * @memberof FTShapeElement
   */
  clipSrc?: string;
  /**
   *
   * @type {string}
   * @memberof FTShapeElement
   */
  borderColor?: string;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  borderSize?: number;
  /**
   *
   * @type {boolean}
   * @memberof FTShapeElement
   */
  keepRatio?: boolean;
  /**
   *
   * @type {FTElementMetadata}
   * @memberof FTShapeElement
   */
  custom?: FTElementMetadata;
  /**
   *
   * @type {string}
   * @memberof FTShapeElement
   */
  subType?: string;
  /**
   *
   * @type {string}
   * @memberof FTShapeElement
   */
  fill?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof FTShapeElement
   */
  dash?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  strokeWidth?: number;
  /**
   *
   * @type {string}
   * @memberof FTShapeElement
   */
  stroke?: string;
  /**
   *
   * @type {number}
   * @memberof FTShapeElement
   */
  cornerRadius?: number;
}

/**
 * @export
 */
export const FTShapeElementTypeEnum = {
  Figure: 'figure',
} as const;
export type FTShapeElementTypeEnum = (typeof FTShapeElementTypeEnum)[keyof typeof FTShapeElementTypeEnum];

/**
 * Check if a given object implements the FTShapeElement interface.
 */
export function instanceOfFTShapeElement(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'type' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'x' in value;
  isInstance = isInstance && 'y' in value;
  isInstance = isInstance && 'width' in value;
  isInstance = isInstance && 'height' in value;

  return isInstance;
}

export function FTShapeElementFromJSON(json: any): FTShapeElement {
  return FTShapeElementFromJSONTyped(json, false);
}

export function FTShapeElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): FTShapeElement {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: json['type'],
    name: json['name'],
    x: json['x'],
    y: json['y'],
    width: json['width'],
    height: json['height'],
    opacity: !exists(json, 'opacity') ? undefined : json['opacity'],
    visible: !exists(json, 'visible') ? undefined : json['visible'],
    selectable: !exists(json, 'selectable') ? undefined : json['selectable'],
    removable: !exists(json, 'removable') ? undefined : json['removable'],
    alwaysOnTop: !exists(json, 'alwaysOnTop') ? undefined : json['alwaysOnTop'],
    rotation: !exists(json, 'rotation') ? undefined : json['rotation'],
    blurEnabled: !exists(json, 'blurEnabled') ? undefined : json['blurEnabled'],
    blurRadius: !exists(json, 'blurRadius') ? undefined : json['blurRadius'],
    brightnessEnabled: !exists(json, 'brightnessEnabled') ? undefined : json['brightnessEnabled'],
    brightness: !exists(json, 'brightness') ? undefined : json['brightness'],
    sepiaEnabled: !exists(json, 'sepiaEnabled') ? undefined : json['sepiaEnabled'],
    grayscaleEnabled: !exists(json, 'grayscaleEnabled') ? undefined : json['grayscaleEnabled'],
    showInExport: !exists(json, 'showInExport') ? undefined : json['showInExport'],
    shadowEnabled: !exists(json, 'shadowEnabled') ? undefined : json['shadowEnabled'],
    shadowBlur: !exists(json, 'shadowBlur') ? undefined : json['shadowBlur'],
    shadowOffsetX: !exists(json, 'shadowOffsetX') ? undefined : json['shadowOffsetX'],
    shadowOffsetY: !exists(json, 'shadowOffsetY') ? undefined : json['shadowOffsetY'],
    shadowColor: !exists(json, 'shadowColor') ? undefined : json['shadowColor'],
    shadowOpacity: !exists(json, 'shadowOpacity') ? undefined : json['shadowOpacity'],
    draggable: !exists(json, 'draggable') ? undefined : json['draggable'],
    resizable: !exists(json, 'resizable') ? undefined : json['resizable'],
    contentEditable: !exists(json, 'contentEditable') ? undefined : json['contentEditable'],
    styleEditable: !exists(json, 'styleEditable') ? undefined : json['styleEditable'],
    flipX: !exists(json, 'flipX') ? undefined : json['flipX'],
    flipY: !exists(json, 'flipY') ? undefined : json['flipY'],
    clipSrc: !exists(json, 'clipSrc') ? undefined : json['clipSrc'],
    borderColor: !exists(json, 'borderColor') ? undefined : json['borderColor'],
    borderSize: !exists(json, 'borderSize') ? undefined : json['borderSize'],
    keepRatio: !exists(json, 'keepRatio') ? undefined : json['keepRatio'],
    custom: !exists(json, 'custom') ? undefined : FTElementMetadataFromJSON(json['custom']),
    subType: !exists(json, 'subType') ? undefined : json['subType'],
    fill: !exists(json, 'fill') ? undefined : json['fill'],
    dash: !exists(json, 'dash') ? undefined : json['dash'],
    strokeWidth: !exists(json, 'strokeWidth') ? undefined : json['strokeWidth'],
    stroke: !exists(json, 'stroke') ? undefined : json['stroke'],
    cornerRadius: !exists(json, 'cornerRadius') ? undefined : json['cornerRadius'],
  };
}

export function FTShapeElementToJSON(value?: FTShapeElement | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    name: value.name,
    x: value.x,
    y: value.y,
    width: value.width,
    height: value.height,
    opacity: value.opacity,
    visible: value.visible,
    selectable: value.selectable,
    removable: value.removable,
    alwaysOnTop: value.alwaysOnTop,
    rotation: value.rotation,
    blurEnabled: value.blurEnabled,
    blurRadius: value.blurRadius,
    brightnessEnabled: value.brightnessEnabled,
    brightness: value.brightness,
    sepiaEnabled: value.sepiaEnabled,
    grayscaleEnabled: value.grayscaleEnabled,
    showInExport: value.showInExport,
    shadowEnabled: value.shadowEnabled,
    shadowBlur: value.shadowBlur,
    shadowOffsetX: value.shadowOffsetX,
    shadowOffsetY: value.shadowOffsetY,
    shadowColor: value.shadowColor,
    shadowOpacity: value.shadowOpacity,
    draggable: value.draggable,
    resizable: value.resizable,
    contentEditable: value.contentEditable,
    styleEditable: value.styleEditable,
    flipX: value.flipX,
    flipY: value.flipY,
    clipSrc: value.clipSrc,
    borderColor: value.borderColor,
    borderSize: value.borderSize,
    keepRatio: value.keepRatio,
    custom: FTElementMetadataToJSON(value.custom),
    subType: value.subType,
    fill: value.fill,
    dash: value.dash,
    strokeWidth: value.strokeWidth,
    stroke: value.stroke,
    cornerRadius: value.cornerRadius,
  };
}
