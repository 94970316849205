/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface InferEditActionResponse
 */
export interface InferEditActionResponse {
  /**
   *
   * @type {string}
   * @memberof InferEditActionResponse
   */
  action: InferEditActionResponseActionEnum;
  /**
   *
   * @type {string}
   * @memberof InferEditActionResponse
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof InferEditActionResponse
   */
  directions: string;
  /**
   *
   * @type {boolean}
   * @memberof InferEditActionResponse
   */
  NSFW: boolean;
}

/**
 * @export
 */
export const InferEditActionResponseActionEnum = {
  BackgroundRemoval: 'background_removal',
  ObjectRemoval: 'object_removal',
  Restyle: 'restyle',
  Unknown: 'unknown',
} as const;
export type InferEditActionResponseActionEnum = (typeof InferEditActionResponseActionEnum)[keyof typeof InferEditActionResponseActionEnum];

/**
 * Check if a given object implements the InferEditActionResponse interface.
 */
export function instanceOfInferEditActionResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'action' in value;
  isInstance = isInstance && 'subject' in value;
  isInstance = isInstance && 'directions' in value;
  isInstance = isInstance && 'NSFW' in value;

  return isInstance;
}

export function InferEditActionResponseFromJSON(json: any): InferEditActionResponse {
  return InferEditActionResponseFromJSONTyped(json, false);
}

export function InferEditActionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InferEditActionResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    action: json['action'],
    subject: json['subject'],
    directions: json['directions'],
    NSFW: json['NSFW'],
  };
}

export function InferEditActionResponseToJSON(value?: InferEditActionResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    action: value.action,
    subject: value.subject,
    directions: value.directions,
    NSFW: value.NSFW,
  };
}
