import { Credits } from '../../generated/app_server_sdk';
import { Button, Tooltip } from '@mui/material';
import { format, parseISO } from 'date-fns';
import Iconify from '../iconify';

export function CreditsCounter(props: { credits: Credits }) {
  return (
    <Tooltip
      title={
        <>
          {props.credits.remaining} out of {props.credits.cap} generations remaining until {format(parseISO(props.credits.until), 'MMM do')}.
        </>
      }
    >
      <Button variant='text' color='secondary' size='small' startIcon={<Iconify icon='fa-regular:gem' width={16} />} sx={{ color: '#4000D5' }}>
        {props.credits.remaining}/{props.credits.cap}
      </Button>
    </Tooltip>
  );
}
