import { UserSettings } from '../../generated/app_server_sdk';

interface LabListEntry {
  // added an id field because of the lookup in AIDesignSection.tsx;
  // I changed the label of the labs field after recording the demo vid
  // and broke the lookup; an unchanging id field would have prevented that
  id: string;
  name: string;
  featureFlag?: keyof UserSettings;
  defaultMediaUrl: string;
  hoverMediaUrl: string;
  icon: string;
  description: string;
  navigateUrl: string;
}

const LABS_LIST: LabListEntry[] = [
  {
    id: 'pedestals',
    name: 'Packshot Pro',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1733140499/app-materials/homepage-cards/packshot-pro_uby7bw.png',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1733140508/app-materials/homepage-cards/packshot-pro-hover_wecf6o.png',
    icon: 'game-icons:flat-platform',
    description: 'Turn your product images into polished packshots with dynamic pedestals.',
    navigateUrl: '/labs/pedestals',
  },
  {
    id: 'text-to-image',
    name: 'Text to Image',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724759255/txt-2-img-example-2_f5y9wq.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724759255/txt-2-img-example-1_xwoqr5.jpg',
    icon: 'ph:pencil-fill',
    description: 'Need a scenery / background? Describe an image you want, and we will create it for you',
    navigateUrl: '/labs/text-to-image',
  },
  {
    id: 'scene-swap',
    name: 'Scene Swap',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1730363300/lab-image_qmztmi.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1730363258/inpaint_11_gfydsx.jpg',
    icon: 'mdi:brush-variant',
    description: 'Change scenery and background for your picture with amazing results!',
    navigateUrl: '/labs/inpaint',
  },
  {
    id: 'restyle-image',
    name: 'Restyling',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1730976808/app-materials/labs/samples/coffee_r7dkxv.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1730976801/app-materials/labs/samples/restyle_a83lrw.jpg',
    icon: 'material-symbols:style',
    description: 'Change the style of your image to your liking!',
    navigateUrl: '/labs/restyle-image',
  },
  {
    id: 'home-staging',
    name: 'Virtual Staging',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724080616/app-materials/homepage-cards/stage-your-place_pgtund.png',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724085434/app-materials/homepage-cards/stage-your-place-hover_tuaxf1.png',
    icon: 'material-symbols:scene',
    description: 'Real Estate professional?  Upload an image of an empty room, and we will decorate it to perfectly suit your needs.',
    navigateUrl: '/labs/home-staging',
  },
  {
    id: 'text-to-graphic-design',
    name: 'AI Graphic Design',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724758051/app-materials/homepage-cards/txt-2-graphics-example2_ps8pda.jpg',

    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724758050/app-materials/homepage-cards/txt-2-graphics-example1_uxxgpx.jpg',
    icon: 'uil:window-grid',
    description: 'From text to graphic design, bring your brief to life with our AI',
    navigateUrl: '/labs/text-to-graphic-design',
  },
  {
    id: 'text-to-subject',
    name: 'Prop Generator',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724757350/app-materials/homepage-cards/text2props-example-1_rldtqs.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724759049/text2props-example-2_ay4h4q.jpg',
    icon: 'material-symbols:emoji-objects-outline',
    description: 'Need an image of specific object? Describe it, and we will create a transparent PNG for you',
    navigateUrl: '/labs/text-to-subject',
  },

  {
    id: 'blend-two-images',
    name: 'Blend two images',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1727608160/bed_u5n1dk.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1727608250/bed-pink_pein6k.jpg',
    icon: 'material-symbols:blender-outline',
    description: 'Give us the content and the style you want and we will mix it up for you!',
    navigateUrl: '/labs/blend-two-images',
  },

  {
    id: 'lora',
    name: 'Train & Create',
    featureFlag: 'lora_training_and_usage',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/v1734858747/app-materials/lora/texttoimage_kmpjhk.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/v1734858747/app-materials/lora/texttoimage_kmpjhk.jpg',
    icon: 'oui:training',
    description: 'Teach the AI new concepts and bring them to life',
    navigateUrl: '/labs/lora',
  },
];
export default LABS_LIST;
