/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateUserSettingsRequest
 */
export interface UpdateUserSettingsRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUserSettingsRequest
   */
  cld_environment_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserSettingsRequest
   */
  cld_secret_key?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserSettingsRequest
   */
  cld_api_key?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserSettingsRequest
   */
  cld_cloud_name?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateUserSettingsRequest
   */
  photoshoot_batch_size?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateUserSettingsRequest
   */
  noop: string;
}

/**
 * Check if a given object implements the UpdateUserSettingsRequest interface.
 */
export function instanceOfUpdateUserSettingsRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'noop' in value;

  return isInstance;
}

export function UpdateUserSettingsRequestFromJSON(json: any): UpdateUserSettingsRequest {
  return UpdateUserSettingsRequestFromJSONTyped(json, false);
}

export function UpdateUserSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserSettingsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cld_environment_id: !exists(json, 'cld_environment_id') ? undefined : json['cld_environment_id'],
    cld_secret_key: !exists(json, 'cld_secret_key') ? undefined : json['cld_secret_key'],
    cld_api_key: !exists(json, 'cld_api_key') ? undefined : json['cld_api_key'],
    cld_cloud_name: !exists(json, 'cld_cloud_name') ? undefined : json['cld_cloud_name'],
    photoshoot_batch_size: !exists(json, 'photoshoot_batch_size') ? undefined : json['photoshoot_batch_size'],
    noop: json['noop'],
  };
}

export function UpdateUserSettingsRequestToJSON(value?: UpdateUserSettingsRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cld_environment_id: value.cld_environment_id,
    cld_secret_key: value.cld_secret_key,
    cld_api_key: value.cld_api_key,
    cld_cloud_name: value.cld_cloud_name,
    photoshoot_batch_size: value.photoshoot_batch_size,
    noop: value.noop,
  };
}
