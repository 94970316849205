/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LoraWeight } from './LoraWeight';
import { LoraWeightFromJSON, LoraWeightFromJSONTyped, LoraWeightToJSON } from './LoraWeight';

/**
 *
 * @export
 * @interface LabsTextToImageLoraRequest
 */
export interface LabsTextToImageLoraRequest {
  /**
   *
   * @type {Array<LoraWeight>}
   * @memberof LabsTextToImageLoraRequest
   */
  lora_weights?: Array<LoraWeight>;
  /**
   *
   * @type {number}
   * @memberof LabsTextToImageLoraRequest
   */
  height: number;
  /**
   *
   * @type {number}
   * @memberof LabsTextToImageLoraRequest
   */
  width: number;
  /**
   *
   * @type {string}
   * @memberof LabsTextToImageLoraRequest
   */
  prompt: string;
}

/**
 * Check if a given object implements the LabsTextToImageLoraRequest interface.
 */
export function instanceOfLabsTextToImageLoraRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'height' in value;
  isInstance = isInstance && 'width' in value;
  isInstance = isInstance && 'prompt' in value;

  return isInstance;
}

export function LabsTextToImageLoraRequestFromJSON(json: any): LabsTextToImageLoraRequest {
  return LabsTextToImageLoraRequestFromJSONTyped(json, false);
}

export function LabsTextToImageLoraRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabsTextToImageLoraRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    lora_weights: !exists(json, 'lora_weights') ? undefined : (json['lora_weights'] as Array<any>).map(LoraWeightFromJSON),
    height: json['height'],
    width: json['width'],
    prompt: json['prompt'],
  };
}

export function LabsTextToImageLoraRequestToJSON(value?: LabsTextToImageLoraRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    lora_weights: value.lora_weights === undefined ? undefined : (value.lora_weights as Array<any>).map(LoraWeightToJSON),
    height: value.height,
    width: value.width,
    prompt: value.prompt,
  };
}
