/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FTElementPermissions
 */
export interface FTElementPermissions {
  /**
   *
   * @type {boolean}
   * @memberof FTElementPermissions
   */
  draggable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTElementPermissions
   */
  contentEditable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTElementPermissions
   */
  styleEditable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTElementPermissions
   */
  resizable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FTElementPermissions
   */
  removable: boolean;
}

/**
 * Check if a given object implements the FTElementPermissions interface.
 */
export function instanceOfFTElementPermissions(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'draggable' in value;
  isInstance = isInstance && 'contentEditable' in value;
  isInstance = isInstance && 'styleEditable' in value;
  isInstance = isInstance && 'resizable' in value;
  isInstance = isInstance && 'removable' in value;

  return isInstance;
}

export function FTElementPermissionsFromJSON(json: any): FTElementPermissions {
  return FTElementPermissionsFromJSONTyped(json, false);
}

export function FTElementPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FTElementPermissions {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    draggable: json['draggable'],
    contentEditable: json['contentEditable'],
    styleEditable: json['styleEditable'],
    resizable: json['resizable'],
    removable: json['removable'],
  };
}

export function FTElementPermissionsToJSON(value?: FTElementPermissions | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    draggable: value.draggable,
    contentEditable: value.contentEditable,
    styleEditable: value.styleEditable,
    resizable: value.resizable,
    removable: value.removable,
  };
}
