import { ComponentProps, ElementType, ReactNode, Suspense, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom';
import { safeLazy as lazy } from 'react-safe-lazy';
import AuthGuard from 'src/auth/AuthGuard';
import { NavigateionFeatureGate } from 'src/auth/FeatureGate';
import LoadingScreen from 'src/components/loading-screen';
import LoginPage from 'src/pages/auth/LoginPage';
import { OnboardingGuard } from '../components/onboarding/OnboardingGuard';
import { AuthenticatedAppContextProviders } from '../layouts/AuthenticatedAppContextProviders';
import CADLayout from '../layouts/editor';

const UserAccountPage = Loadable(lazy(() => import('src/pages/account/UserAccountPage')));
const Editor = Loadable(lazy(() => import('src/pages/virtual-photoshoot/PhotoshootEditorPage')));
const AssetEditorPageGpt = Loadable(lazy(() => import('src/pages/asset/AssetEditorPageGpt')));
const AssetScenePicker = Loadable(lazy(() => import('src/pages/asset/AssetScenePicker')));
const Assets = Loadable(lazy(() => import('src/pages/asset/AssetsList')));
const MyDesignsPage = Loadable(lazy(() => import('src/pages/designs/MyDesignsPage')));
const DesignsDashboardPage = Loadable(lazy(() => import('src/pages/designs/DesignsDashboardPage')));
const Creations = Loadable(lazy(() => import('src/pages/bounty/Creations')));
const Homepage = Loadable(lazy(() => import('src/pages/home/Homepage')));
const MobileHomepage = Loadable(lazy(() => import('src/pages/home/MobileHomepage')));
const Page404 = Loadable(lazy(() => import('src/pages/Page404')));
const PhotoEditorPage = Loadable(lazy(() => import('src/pages/photo-editor/PhotoEditorPage')));
const CreateSceneWithPicturesPage = Loadable(lazy(() => import('src/pages/scenes/CreateSceneWithPicturesPage')));
const HomeStaging = Loadable(lazy(() => import('src/pages/labs/HomeStagingPage')));
const TextToImagePage = Loadable(lazy(() => import('src/pages/labs/TextToImagePage')));
const MobileTextToImagePage = Loadable(lazy(() => import('src/pages/labs/MobileTextToImagePage')));
const TextToSubjectPage = Loadable(lazy(() => import('src/pages/labs/TextToSubjectPage')));
const TextToGraphicDesignPage = Loadable(lazy(() => import('src/pages/labs/TextToGraphicDesignPage')));
const PackshotPage = Loadable(lazy(() => import('src/pages/labs/PackshotPage')));
const AlingProductsPlatformsPage = Loadable(lazy(() => import('src/pages/labs/AlingProductsPlatformsPage')));
const BlendTwoImagesPage = Loadable(lazy(() => import('src/pages/labs/BlendTwoImagesPage')));
const RestyleImagePage = Loadable(lazy(() => import('src/pages/labs/RestyleImagePage')));
const ConversationalPage = Loadable(lazy(() => import('src/pages/labs/ConversationalPage')));
const StudioShotPage = Loadable(lazy(() => import('src/pages/labs/StudioShotPage')));
const StandaloneEditorPage = Loadable(lazy(() => import('src/pages/standalone-editor/StandaloneEditorPage')));
const InpaintPage = Loadable(lazy(() => import('src/pages/labs/InpaintPage')));
const PedestalsLabPage = Loadable(lazy(() => import('src/pages/labs/PedestalsLab')));

const HolidayPedestalsLabPage = Loadable(lazy(() => import('src/pages/labs/HolidayPedestalsLab')));
const MobilePedestalsLabPage = Loadable(lazy(() => import('src/pages/labs/MobilePackshotPro')));
const HolidayStudioShotPage = Loadable(lazy(() => import('src/pages/labs/HolidayStudioShotPage')));
const HolidayStylingPage = Loadable(lazy(() => import('src/pages/labs/HolidayStylingPage')));
const HolidayTextToGraphicDesignPage = Loadable(lazy(() => import('src/pages/labs/HolidayTextToGraphicDesignPage')));
const OnboardingFormPage = Loadable(lazy(() => import('src/pages/onboarding/OnboardingFormPage')));
const DamLogin = Loadable(lazy(() => import('src/pages/auth/DamLogin')));
const PackshotProCustomAction = Loadable(lazy(() => import('src/pages/cloudinary/dam/PackshotProCustomAction')));
const EditorCustomAction = Loadable(lazy(() => import('src/pages/cloudinary/dam/EditorCustomAction')));
const NewLoraPage = Loadable(lazy(() => import('src/pages/labs/lora/NewLoraPage')));
const ListLoraPage = Loadable(lazy(() => import('src/pages/labs/lora/ListLoraPage')));
const ShowLoraPage = Loadable(lazy(() => import('src/pages/labs/lora/ShowLoraPage')));
const SantaLabPage = Loadable(lazy(() => import('src/pages/labs/lora/SantaLabPage')));
const OpenPromptWithLoraPage = Loadable(lazy(() => import('src/pages/labs/lora/OpenPromptWithLoraPage')));

// ----------------------------------------------------------------------

const ApplicationWrapper = ({ children }: { children: ReactNode }) => (
  <AuthGuard>
    <OnboardingGuard>
      <AuthenticatedAppContextProviders>{children}</AuthenticatedAppContextProviders>
    </OnboardingGuard>
  </AuthGuard>
);

const CldDamApplicationWrapper = ({ children }: { children: ReactNode }) => <AuthGuard>{children}</AuthGuard>;

export default function Router() {
  const location = useLocation();

  useEffect(() => {
    const handlePopState = () => {
      if (location.pathname.startsWith('/editor')) {
        window.location.reload(); // Forces a full reload
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location.pathname]);

  let routes = [
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/onboarding',
      element: (
        <AuthGuard>
          <OnboardingFormPage />
        </AuthGuard>
      ),
    },
    {
      path: '/photo-editor',
      element: (
        <ApplicationWrapper>
          <Outlet />
        </ApplicationWrapper>
      ),
      children: [{ path: '', element: <PhotoEditorPage /> }],
    },
    {
      path: '/editor/:id?',
      element: (
        <ApplicationWrapper>
          <StandaloneEditorPage />
        </ApplicationWrapper>
      ),
    },
    {
      path: '/integrations/cloudinary/dam-app/auth/',
      element: <DamLogin />,
    },
    {
      path: '/integrations/cloudinary/dam-app/',
      element: (
        <CldDamApplicationWrapper>
          <AuthenticatedAppContextProviders>
            <Outlet />
          </AuthenticatedAppContextProviders>
        </CldDamApplicationWrapper>
      ),
      children: [
        {
          path: 'packshot-pro',
          children: [{ path: '', element: <PackshotProCustomAction /> }],
        },
        {
          path: 'editor',
          children: [{ path: '', element: <EditorCustomAction /> }],
        },
      ],
    },
    {
      path: '/',
      element: (
        <ApplicationWrapper>
          <CADLayout />
        </ApplicationWrapper>
      ),
      children: [
        { path: '', element: isMobile ? <MobileHomepage /> : <DesignsDashboardPage /> },
        { path: 'edit', element: <Editor /> },
        { path: '404', element: <Page404 /> },
        { path: 'home-staging', element: <Navigate to='/labs/home-staging' replace /> },
        {
          path: '/labs',
          children: [
            { path: 'home-staging', element: <HomeStaging /> },
            { path: 'text-to-image', element: isMobile ? <MobileTextToImagePage /> : <TextToImagePage /> },
            { path: 'text-to-subject', element: <TextToSubjectPage /> },
            { path: 'text-to-graphic-design', element: <TextToGraphicDesignPage /> },
            { path: 'pack-shot', element: <PackshotPage /> },
            { path: 'align-products-on-platforms', element: <AlingProductsPlatformsPage /> },
            { path: 'blend-two-images', element: <BlendTwoImagesPage /> },
            { path: 'restyle-image', element: <RestyleImagePage /> },
            { path: 'conversational-editing', element: <ConversationalPage /> },
            { path: 'studio-shot', element: <StudioShotPage /> },
            { path: 'inpaint', element: <InpaintPage /> },
            { path: 'pedestals', element: isMobile ? <MobilePedestalsLabPage /> : <PedestalsLabPage /> },

            { path: 'holiday-pedestals', element: <HolidayPedestalsLabPage /> },
            { path: 'holiday-studio-shot', element: <HolidayStudioShotPage /> },
            { path: 'holiday-styling', element: <HolidayStylingPage /> },
            { path: 'holiday-text-to-graphic-design', element: <HolidayTextToGraphicDesignPage /> },
            {
              path: 'lora',
              children: [
                { path: 'list', element: <ListLoraPage /> },
                { path: 'new', element: <NewLoraPage /> },
                { path: 'show/:id', element: <ShowLoraPage /> },
                { path: 'santa-wonderland', element: <SantaLabPage /> },
                { path: 'text-to-image', element: <OpenPromptWithLoraPage /> },

                { element: <Navigate to='/labs/lora/list' replace />, index: true },
              ],
              element: <NavigateionFeatureGate flag='lora_training_and_usage' value='true' navigateOnError='/' />,
            },
            { path: '*', element: <Navigate to='/' replace /> },
          ],
        },
        {
          path: '/creations',
          children: [
            { element: <Navigate to='/creations/list' replace />, index: true },
            { path: 'create', element: <Editor /> },
            { path: 'list', element: <Creations /> },
          ],
        },
        {
          path: '/assets',

          children: [
            { element: <Navigate to='/assets/list' replace />, index: true },
            { path: 'list', element: <Assets /> },
            { path: 'edit/:assetId', element: <AssetEditorPageGpt /> },
            { path: 'edit/:assetId/scenes', element: <AssetScenePicker /> },
          ],
        },
        {
          path: '/designs',

          children: [
            { element: <Navigate to='/designs/dashboard' replace />, index: true },
            { path: 'dashboard', element: <DesignsDashboardPage /> },
            { path: 'my-designs', element: <MyDesignsPage /> },
          ],
        },
        {
          path: '/account',

          children: [
            { element: <Navigate to='/account/settings/general' replace />, index: true },
            { path: 'settings', element: <Navigate to='/account/settings/general' replace /> },
            { path: 'settings/:tab', element: <UserAccountPage /> },
          ],
        },
        {
          path: '/scenes',
          children: [
            { element: <Navigate to='/scenes/create' replace />, index: true },
            { path: 'create', element: <CreateSceneWithPicturesPage /> },
          ],
        },

        {
          path: '/virtual-photoshoot',
          element: <Homepage />,
        },

        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
  ];

  return useRoutes(routes);
}
export function Loadable<T extends ElementType>(Component: T) {
  return (props: ComponentProps<T>) => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
}
