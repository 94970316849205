import { readAndCompressImage } from 'browser-image-resizer';
import { md5checksum } from './imageUtils';

export interface IProcessedFile {
  file: File;
  reader: FileReader;
  image: HTMLImageElement;
  preview: string;
}
export interface Base64File {
  mimetype: string;
  content: string;
  name?: string;
}
export interface IFileProcessingOptions {
  downscale?: boolean;
}

export class ProcessedFile implements IProcessedFile {
  file: File;
  reader: FileReader;
  image: HTMLImageElement;
  checksum: string;
  base64: string;

  constructor(file: File, reader: FileReader, image: HTMLImageElement, base64: string, checksum: string) {
    this.file = file;
    this.reader = reader;
    this.image = image;
    this.base64 = base64;
    this.checksum = checksum;
  }

  asBase64File(): Base64File {
    const parts = this.base64.split(',');
    const header = parts[0];
    const content = parts[1];
    const mimetype = header.split(';')[0].replace('data:', '');

    return {
      mimetype,
      content,
    };
  }

  asBlob(): Blob {
    return this.file;
  }

  static empty() {
    let file = new File([], 'empty');
    let reader = new FileReader();
    let image = document.createElement('img') as HTMLImageElement;
    return new ProcessedFile(file, reader, image, '', '');
  }

  static async fromUrl(url: string, options: IFileProcessingOptions = {}): Promise<ProcessedFile> {
    if (options.downscale === undefined) {
      options.downscale = true;
    }
    let response = await fetch(url);
    let file = (await response.blob()) as File;
    let scaled: Blob;
    if (!options.downscale) {
      scaled = file;
    } else {
      scaled = await readAndCompressImage(file, { maxWidth: 1024, maxHeight: 1024, mimeType: 'image/png' });
    }

    const checksum = await md5checksum(file);

    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        var image = new Image();
        image.onload = function () {
          resolve(new ProcessedFile(file, reader, image, base64, checksum));
        };
        image.onerror = (e) => {
          reject(e);
        };
        image.src = options.downscale ? String(base64) : url;
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsDataURL(scaled);
    });
  }

  static async fromBlob(blob: Blob, name: string = 'unnamed', options: IFileProcessingOptions = {}): Promise<ProcessedFile> {
    var file = new File([blob], name);
    return ProcessedFile.fromFile(file, options);
  }

  static async fromFile(file: File, options: IFileProcessingOptions = {}): Promise<ProcessedFile> {
    // TODO width / height is set to 512x512 and should be passed as an argument passing the canvas size
    // also the file is scaled down - original is not saved
    if (options.downscale === undefined) {
      options.downscale = true;
    }
    let scaled: Blob;
    if (!options.downscale) {
      scaled = file;
    } else {
      scaled = await readAndCompressImage(file, { maxWidth: 1024, maxHeight: 1024, mimeType: 'image/png' });
    }
    const checksum = await md5checksum(file);

    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        var image = new Image();
        image.onload = function () {
          resolve(new ProcessedFile(file, reader, image, base64, checksum));
        };
        image.onerror = (e) => {
          reject(e);
        };
        image.src = base64;
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsDataURL(scaled);
    });
  }

  get preview() {
    return this.image.src;
  }
}

export default ProcessedFile;
