import { CloudinaryMediaLibraryWidgetContextProvider } from 'src/components/cloudinary/MediaLibraryCloudinaryContext';
import { CreditsContextProvider } from '../components/credits-context/CreditsContext';
import { AppCacheProvider } from '../components/flow/AppCacheContext';
import { UploadDialogContextProvider } from '../components/upload-dialog-context/UploadDialogContext';
export function AuthenticatedAppContextProviders({ children }: { children: React.ReactNode }) {
  return (
    <CreditsContextProvider>
      <AppCacheProvider>
        <CloudinaryMediaLibraryWidgetContextProvider>
          <UploadDialogContextProvider>{children}</UploadDialogContextProvider>
        </CloudinaryMediaLibraryWidgetContextProvider>
      </AppCacheProvider>
    </CreditsContextProvider>
  );
}
