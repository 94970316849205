/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AnalyticsAck
 */
export interface AnalyticsAck {
  /**
   *
   * @type {number}
   * @memberof AnalyticsAck
   */
  ok: number;
}

/**
 * Check if a given object implements the AnalyticsAck interface.
 */
export function instanceOfAnalyticsAck(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'ok' in value;

  return isInstance;
}

export function AnalyticsAckFromJSON(json: any): AnalyticsAck {
  return AnalyticsAckFromJSONTyped(json, false);
}

export function AnalyticsAckFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyticsAck {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ok: json['ok'],
  };
}

export function AnalyticsAckToJSON(value?: AnalyticsAck | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ok: value.ok,
  };
}
