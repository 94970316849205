/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DeletedEntityResponse
 */
export interface DeletedEntityResponse {
  /**
   *
   * @type {string}
   * @memberof DeletedEntityResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DeletedEntityResponse
   */
  type: string;
}

/**
 * Check if a given object implements the DeletedEntityResponse interface.
 */
export function instanceOfDeletedEntityResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'type' in value;

  return isInstance;
}

export function DeletedEntityResponseFromJSON(json: any): DeletedEntityResponse {
  return DeletedEntityResponseFromJSONTyped(json, false);
}

export function DeletedEntityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeletedEntityResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: json['type'],
  };
}

export function DeletedEntityResponseToJSON(value?: DeletedEntityResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
  };
}
