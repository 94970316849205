/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  Asset,
  AssetListing,
  AssetUpdateRequestPayload,
  BadRequestResponse,
  Collaborator,
  CreateProductRequest,
  DeleteAssetCollaboratorRequest,
  DeletedEntityResponse,
  FindOneAssetByRequestParams,
  ListCollaboratorsResponse,
  ListCollectionsResponse,
  Product,
  ProductListing,
  UpdateAssetCollaboratorRequest,
} from '../models';
import {
  AssetFromJSON,
  AssetToJSON,
  AssetListingFromJSON,
  AssetListingToJSON,
  AssetUpdateRequestPayloadFromJSON,
  AssetUpdateRequestPayloadToJSON,
  BadRequestResponseFromJSON,
  BadRequestResponseToJSON,
  CollaboratorFromJSON,
  CollaboratorToJSON,
  CreateProductRequestFromJSON,
  CreateProductRequestToJSON,
  DeleteAssetCollaboratorRequestFromJSON,
  DeleteAssetCollaboratorRequestToJSON,
  DeletedEntityResponseFromJSON,
  DeletedEntityResponseToJSON,
  FindOneAssetByRequestParamsFromJSON,
  FindOneAssetByRequestParamsToJSON,
  ListCollaboratorsResponseFromJSON,
  ListCollaboratorsResponseToJSON,
  ListCollectionsResponseFromJSON,
  ListCollectionsResponseToJSON,
  ProductFromJSON,
  ProductToJSON,
  ProductListingFromJSON,
  ProductListingToJSON,
  UpdateAssetCollaboratorRequestFromJSON,
  UpdateAssetCollaboratorRequestToJSON,
} from '../models';

export interface AssetsApiCreateAssetRequest {
  image: Blob;
  origin: CreateAssetOriginEnum;
  name?: string;
  async?: boolean;
  canvas?: object;
  analyze_content?: boolean;
  segment_foreground?: boolean;
}

export interface AssetsApiCreateProductOperationRequest {
  create_product_request?: CreateProductRequest;
}

export interface AssetsApiDeleteAssetRequest {
  id: string;
}

export interface AssetsApiDeleteAssetCollaboratorOperationRequest {
  delete_asset_collaborator_request?: DeleteAssetCollaboratorRequest;
}

export interface AssetsApiFindOneAssetByRequest {
  find_one_asset_by_request_params?: FindOneAssetByRequestParams;
}

export interface AssetsApiGetAssetByIdRequest {
  id: string;
}

export interface AssetsApiGetAssetTemplateByIdRequest {
  id: string;
}

export interface AssetsApiImportAssetRequest {
  image: Blob;
  filename?: string;
}

export interface AssetsApiListAssetCollaboratorsRequest {
  id: string;
}

export interface AssetsApiListAssetsRequest {
  max_items?: number;
  cursor_serial?: number;
  origin?: Array<ListAssetsOriginEnum>;
}

export interface AssetsApiListCollaboratedAssetsRequest {
  max_items?: number;
  cursor?: string;
  role?: ListCollaboratedAssetsRoleEnum;
}

export interface AssetsApiListCollectionsRequest {
  sharing: ListCollectionsSharingEnum;
}

export interface AssetsApiProductSearchRequest {
  asset_id?: string;
  name?: string;
}

export interface AssetsApiProductShowRequest {
  id: string;
}

export interface AssetsApiSearchAssetsRequest {
  search_term: string;
  status?: Array<SearchAssetsStatusEnum>;
}

export interface AssetsApiUpdateAssetRequest {
  id: string;
  asset_update_request_payload?: AssetUpdateRequestPayload;
}

export interface AssetsApiUpdateAssetCollaboratorOperationRequest {
  update_asset_collaborator_request?: UpdateAssetCollaboratorRequest;
}

/**
 *
 */
export class AssetsApi extends runtime.BaseAPI {
  /**
   */
  async createAssetRaw(
    requestParameters: AssetsApiCreateAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Asset>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling createAsset.');
    }

    if (requestParameters.origin === null || requestParameters.origin === undefined) {
      throw new runtime.RequiredError('origin', 'Required parameter requestParameters.origin was null or undefined when calling createAsset.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.name !== undefined) {
      formParams.append('name', requestParameters.name as any);
    }

    if (requestParameters.async !== undefined) {
      formParams.append('async', requestParameters.async as any);
    }

    if (requestParameters.canvas !== undefined) {
      formParams.append('canvas', JSON.stringify(requestParameters.canvas));
    }

    if (requestParameters.analyze_content !== undefined) {
      formParams.append('analyze_content', requestParameters.analyze_content as any);
    }

    if (requestParameters.segment_foreground !== undefined) {
      formParams.append('segment_foreground', requestParameters.segment_foreground as any);
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.origin !== undefined) {
      formParams.append('origin', requestParameters.origin as any);
    }

    const response = await this.request(
      {
        path: `/assets/create-asset`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
  }

  /**
   */
  async createAsset(
    image: Blob,
    origin: CreateAssetOriginEnum,
    name?: string,
    async?: boolean,
    canvas?: object,
    analyze_content?: boolean,
    segment_foreground?: boolean,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Asset> {
    const response = await this.createAssetRaw(
      { image: image, origin: origin, name: name, async: async, canvas: canvas, analyze_content: analyze_content, segment_foreground: segment_foreground },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async createProductRaw(
    requestParameters: AssetsApiCreateProductOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Product>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/products/new`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateProductRequestToJSON(requestParameters.create_product_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
  }

  /**
   */
  async createProduct(create_product_request?: CreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
    const response = await this.createProductRaw({ create_product_request: create_product_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async deleteAssetRaw(
    requestParameters: AssetsApiDeleteAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<DeletedEntityResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteAsset.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets/delete/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DeletedEntityResponseFromJSON(jsonValue));
  }

  /**
   */
  async deleteAsset(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeletedEntityResponse> {
    const response = await this.deleteAssetRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async deleteAssetCollaboratorRaw(
    requestParameters: AssetsApiDeleteAssetCollaboratorOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<DeletedEntityResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/collaborators/delete`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeleteAssetCollaboratorRequestToJSON(requestParameters.delete_asset_collaborator_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DeletedEntityResponseFromJSON(jsonValue));
  }

  /**
   */
  async deleteAssetCollaborator(
    delete_asset_collaborator_request?: DeleteAssetCollaboratorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DeletedEntityResponse> {
    const response = await this.deleteAssetCollaboratorRaw({ delete_asset_collaborator_request: delete_asset_collaborator_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async findOneAssetByRaw(
    requestParameters: AssetsApiFindOneAssetByRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Asset>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets/find-one-asset-by`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FindOneAssetByRequestParamsToJSON(requestParameters.find_one_asset_by_request_params),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
  }

  /**
   */
  async findOneAssetBy(
    find_one_asset_by_request_params?: FindOneAssetByRequestParams,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Asset> {
    const response = await this.findOneAssetByRaw({ find_one_asset_by_request_params: find_one_asset_by_request_params }, initOverrides);
    return await response.value();
  }

  /**
   */
  async getAssetByIdRaw(
    requestParameters: AssetsApiGetAssetByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Asset>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getAssetById.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets/get-by-id/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
  }

  /**
   */
  async getAssetById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Asset> {
    const response = await this.getAssetByIdRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async getAssetTemplateByIdRaw(
    requestParameters: AssetsApiGetAssetTemplateByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Asset>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getAssetTemplateById.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets/get-asset-template-by-id/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
  }

  /**
   */
  async getAssetTemplateById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Asset> {
    const response = await this.getAssetTemplateByIdRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async importAssetRaw(
    requestParameters: AssetsApiImportAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Asset>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling importAsset.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.filename !== undefined) {
      formParams.append('filename', requestParameters.filename as any);
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/assets/import-asset`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
  }

  /**
   */
  async importAsset(image: Blob, filename?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Asset> {
    const response = await this.importAssetRaw({ image: image, filename: filename }, initOverrides);
    return await response.value();
  }

  /**
   */
  async listAssetCollaboratorsRaw(
    requestParameters: AssetsApiListAssetCollaboratorsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ListCollaboratorsResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling listAssetCollaborators.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/collaborators/asset/list/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ListCollaboratorsResponseFromJSON(jsonValue));
  }

  /**
   */
  async listAssetCollaborators(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListCollaboratorsResponse> {
    const response = await this.listAssetCollaboratorsRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async listAssetsRaw(
    requestParameters: AssetsApiListAssetsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AssetListing>> {
    const queryParameters: any = {};

    if (requestParameters.max_items !== undefined) {
      queryParameters['max_items'] = requestParameters.max_items;
    }

    if (requestParameters.cursor_serial !== undefined) {
      queryParameters['cursor_serial'] = requestParameters.cursor_serial;
    }

    if (requestParameters.origin) {
      queryParameters['origin'] = requestParameters.origin;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets/list-assets`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetListingFromJSON(jsonValue));
  }

  /**
   */
  async listAssets(
    max_items?: number,
    cursor_serial?: number,
    origin?: Array<ListAssetsOriginEnum>,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AssetListing> {
    const response = await this.listAssetsRaw({ max_items: max_items, cursor_serial: cursor_serial, origin: origin }, initOverrides);
    return await response.value();
  }

  /**
   */
  async listCollaboratedAssetsRaw(
    requestParameters: AssetsApiListCollaboratedAssetsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ListCollaboratorsResponse>> {
    const queryParameters: any = {};

    if (requestParameters.max_items !== undefined) {
      queryParameters['max_items'] = requestParameters.max_items;
    }

    if (requestParameters.cursor !== undefined) {
      queryParameters['cursor'] = requestParameters.cursor;
    }

    if (requestParameters.role !== undefined) {
      queryParameters['role'] = requestParameters.role;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/collaborators/collaborated-assets/list`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ListCollaboratorsResponseFromJSON(jsonValue));
  }

  /**
   */
  async listCollaboratedAssets(
    max_items?: number,
    cursor?: string,
    role?: ListCollaboratedAssetsRoleEnum,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ListCollaboratorsResponse> {
    const response = await this.listCollaboratedAssetsRaw({ max_items: max_items, cursor: cursor, role: role }, initOverrides);
    return await response.value();
  }

  /**
   */
  async listCollectionsRaw(
    requestParameters: AssetsApiListCollectionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ListCollectionsResponse>> {
    if (requestParameters.sharing === null || requestParameters.sharing === undefined) {
      throw new runtime.RequiredError('sharing', 'Required parameter requestParameters.sharing was null or undefined when calling listCollections.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/collections/list/{sharing}`.replace(`{${'sharing'}}`, encodeURIComponent(String(requestParameters.sharing))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ListCollectionsResponseFromJSON(jsonValue));
  }

  /**
   */
  async listCollections(sharing: ListCollectionsSharingEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListCollectionsResponse> {
    const response = await this.listCollectionsRaw({ sharing: sharing }, initOverrides);
    return await response.value();
  }

  /**
   */
  async productSearchRaw(
    requestParameters: AssetsApiProductSearchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProductListing>> {
    const queryParameters: any = {};

    if (requestParameters.asset_id !== undefined) {
      queryParameters['asset_id'] = requestParameters.asset_id;
    }

    if (requestParameters.name !== undefined) {
      queryParameters['name'] = requestParameters.name;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/products/search`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductListingFromJSON(jsonValue));
  }

  /**
   */
  async productSearch(asset_id?: string, name?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductListing> {
    const response = await this.productSearchRaw({ asset_id: asset_id, name: name }, initOverrides);
    return await response.value();
  }

  /**
   */
  async productShowRaw(
    requestParameters: AssetsApiProductShowRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Product>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling productShow.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/products/show/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
  }

  /**
   */
  async productShow(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
    const response = await this.productShowRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async searchAssetsRaw(
    requestParameters: AssetsApiSearchAssetsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AssetListing>> {
    if (requestParameters.search_term === null || requestParameters.search_term === undefined) {
      throw new runtime.RequiredError('search_term', 'Required parameter requestParameters.search_term was null or undefined when calling searchAssets.');
    }

    const queryParameters: any = {};

    if (requestParameters.status) {
      queryParameters['status'] = requestParameters.status;
    }

    if (requestParameters.search_term !== undefined) {
      queryParameters['search_term'] = requestParameters.search_term;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets/search-assets`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetListingFromJSON(jsonValue));
  }

  /**
   */
  async searchAssets(
    search_term: string,
    status?: Array<SearchAssetsStatusEnum>,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AssetListing> {
    const response = await this.searchAssetsRaw({ search_term: search_term, status: status }, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateAssetRaw(
    requestParameters: AssetsApiUpdateAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Asset>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateAsset.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/assets/update/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: AssetUpdateRequestPayloadToJSON(requestParameters.asset_update_request_payload),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
  }

  /**
   */
  async updateAsset(
    id: string,
    asset_update_request_payload?: AssetUpdateRequestPayload,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Asset> {
    const response = await this.updateAssetRaw({ id: id, asset_update_request_payload: asset_update_request_payload }, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateAssetCollaboratorRaw(
    requestParameters: AssetsApiUpdateAssetCollaboratorOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Collaborator>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/collaborators/asset/update-collaborator`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateAssetCollaboratorRequestToJSON(requestParameters.update_asset_collaborator_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CollaboratorFromJSON(jsonValue));
  }

  /**
   */
  async updateAssetCollaborator(
    update_asset_collaborator_request?: UpdateAssetCollaboratorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Collaborator> {
    const response = await this.updateAssetCollaboratorRaw({ update_asset_collaborator_request: update_asset_collaborator_request }, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const CreateAssetOriginEnum = {
  GenerativePackshot: 'generative_packshot',
  UserProduct: 'user_product',
  GeneratedProp: 'generated_prop',
  TextToGraphicDesign: 'text_to_graphic_design',
  TextToImage: 'text_to_image',
  VirtualStaging: 'virtual_staging',
  RestyleImage: 'restyle_image',
  BlendTwoImages: 'blend_two_images',
  Inpaint: 'inpaint',
  StudioShot: 'studio_shot',
  HomeStaging: 'home_staging',
  Editor: 'editor',
  LoraInputImage: 'lora_input_image',
  LoraTextToImage: 'lora_text_to_image',
  UserImage: 'user_image',
  Template: 'template',
} as const;
export type CreateAssetOriginEnum = (typeof CreateAssetOriginEnum)[keyof typeof CreateAssetOriginEnum];
/**
 * @export
 */
export const ListAssetsOriginEnum = {
  GenerativePackshot: 'generative_packshot',
  UserProduct: 'user_product',
  GeneratedProp: 'generated_prop',
  TextToGraphicDesign: 'text_to_graphic_design',
  TextToImage: 'text_to_image',
  VirtualStaging: 'virtual_staging',
  RestyleImage: 'restyle_image',
  BlendTwoImages: 'blend_two_images',
  Inpaint: 'inpaint',
  StudioShot: 'studio_shot',
  HomeStaging: 'home_staging',
  Editor: 'editor',
  LoraInputImage: 'lora_input_image',
  LoraTextToImage: 'lora_text_to_image',
  UserImage: 'user_image',
  Template: 'template',
} as const;
export type ListAssetsOriginEnum = (typeof ListAssetsOriginEnum)[keyof typeof ListAssetsOriginEnum];
/**
 * @export
 */
export const ListCollaboratedAssetsRoleEnum = {
  Viewer: 'viewer',
  Editor: 'editor',
} as const;
export type ListCollaboratedAssetsRoleEnum = (typeof ListCollaboratedAssetsRoleEnum)[keyof typeof ListCollaboratedAssetsRoleEnum];
/**
 * @export
 */
export const ListCollectionsSharingEnum = {
  Private: 'private',
  Public: 'public',
  Organization: 'organization',
} as const;
export type ListCollectionsSharingEnum = (typeof ListCollectionsSharingEnum)[keyof typeof ListCollectionsSharingEnum];
/**
 * @export
 */
export const SearchAssetsStatusEnum = {
  New: 'new',
  Active: 'active',
  Deleted: 'deleted',
} as const;
export type SearchAssetsStatusEnum = (typeof SearchAssetsStatusEnum)[keyof typeof SearchAssetsStatusEnum];
