/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FTCanvas } from './FTCanvas';
import { FTCanvasFromJSON, FTCanvasFromJSONTyped, FTCanvasToJSON } from './FTCanvas';
import type { FTWorksheetFontsInner } from './FTWorksheetFontsInner';
import { FTWorksheetFontsInnerFromJSON, FTWorksheetFontsInnerFromJSONTyped, FTWorksheetFontsInnerToJSON } from './FTWorksheetFontsInner';
import type { FTWorksheetMetadata } from './FTWorksheetMetadata';
import { FTWorksheetMetadataFromJSON, FTWorksheetMetadataFromJSONTyped, FTWorksheetMetadataToJSON } from './FTWorksheetMetadata';

/**
 *
 * @export
 * @interface FTWorksheet
 */
export interface FTWorksheet {
  /**
   *
   * @type {number}
   * @memberof FTWorksheet
   */
  width: number;
  /**
   *
   * @type {number}
   * @memberof FTWorksheet
   */
  height: number;
  /**
   *
   * @type {string}
   * @memberof FTWorksheet
   */
  unit: string;
  /**
   *
   * @type {number}
   * @memberof FTWorksheet
   */
  dpi: number;
  /**
   *
   * @type {Array<FTCanvas>}
   * @memberof FTWorksheet
   */
  pages: Array<FTCanvas>;
  /**
   *
   * @type {Array<FTWorksheetFontsInner>}
   * @memberof FTWorksheet
   */
  fonts: Array<FTWorksheetFontsInner>;
  /**
   *
   * @type {FTWorksheetMetadata}
   * @memberof FTWorksheet
   */
  custom: FTWorksheetMetadata;
}

/**
 * Check if a given object implements the FTWorksheet interface.
 */
export function instanceOfFTWorksheet(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'width' in value;
  isInstance = isInstance && 'height' in value;
  isInstance = isInstance && 'unit' in value;
  isInstance = isInstance && 'dpi' in value;
  isInstance = isInstance && 'pages' in value;
  isInstance = isInstance && 'fonts' in value;
  isInstance = isInstance && 'custom' in value;

  return isInstance;
}

export function FTWorksheetFromJSON(json: any): FTWorksheet {
  return FTWorksheetFromJSONTyped(json, false);
}

export function FTWorksheetFromJSONTyped(json: any, ignoreDiscriminator: boolean): FTWorksheet {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    width: json['width'],
    height: json['height'],
    unit: json['unit'],
    dpi: json['dpi'],
    pages: (json['pages'] as Array<any>).map(FTCanvasFromJSON),
    fonts: (json['fonts'] as Array<any>).map(FTWorksheetFontsInnerFromJSON),
    custom: FTWorksheetMetadataFromJSON(json['custom']),
  };
}

export function FTWorksheetToJSON(value?: FTWorksheet | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    width: value.width,
    height: value.height,
    unit: value.unit,
    dpi: value.dpi,
    pages: (value.pages as Array<any>).map(FTCanvasToJSON),
    fonts: (value.fonts as Array<any>).map(FTWorksheetFontsInnerToJSON),
    custom: FTWorksheetMetadataToJSON(value.custom),
  };
}
