/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  BadRequestResponse,
  CloudinaryNamedTransformationResponse,
  CloudinaryUploadResponse,
  CreateCloudinaryTransformationRequest,
  DeleteCloudinaryTransformationRequest,
  ExportAssetToCloudinaryRequest,
} from '../models';
import {
  BadRequestResponseFromJSON,
  BadRequestResponseToJSON,
  CloudinaryNamedTransformationResponseFromJSON,
  CloudinaryNamedTransformationResponseToJSON,
  CloudinaryUploadResponseFromJSON,
  CloudinaryUploadResponseToJSON,
  CreateCloudinaryTransformationRequestFromJSON,
  CreateCloudinaryTransformationRequestToJSON,
  DeleteCloudinaryTransformationRequestFromJSON,
  DeleteCloudinaryTransformationRequestToJSON,
  ExportAssetToCloudinaryRequestFromJSON,
  ExportAssetToCloudinaryRequestToJSON,
} from '../models';

export interface IntegrationsApiCreateCloudinaryTransformationOperationRequest {
  create_cloudinary_transformation_request?: CreateCloudinaryTransformationRequest;
}

export interface IntegrationsApiDeleteCloudinaryTransformationOperationRequest {
  delete_cloudinary_transformation_request?: DeleteCloudinaryTransformationRequest;
}

export interface IntegrationsApiExportAssetToCloudinaryOperationRequest {
  export_asset_to_cloudinary_request?: ExportAssetToCloudinaryRequest;
}

export interface IntegrationsApiExportBlobToCloudinaryRequest {
  image: Blob;
  cld_public_id: string;
  cld_related_id?: string;
  cld_display_name?: string;
}

/**
 *
 */
export class IntegrationsApi extends runtime.BaseAPI {
  /**
   */
  async createCloudinaryTransformationRaw(
    requestParameters: IntegrationsApiCreateCloudinaryTransformationOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CloudinaryNamedTransformationResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/create-cloudinary-transformation`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateCloudinaryTransformationRequestToJSON(requestParameters.create_cloudinary_transformation_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CloudinaryNamedTransformationResponseFromJSON(jsonValue));
  }

  /**
   */
  async createCloudinaryTransformation(
    create_cloudinary_transformation_request?: CreateCloudinaryTransformationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CloudinaryNamedTransformationResponse> {
    const response = await this.createCloudinaryTransformationRaw(
      { create_cloudinary_transformation_request: create_cloudinary_transformation_request },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async deleteCloudinaryTransformationRaw(
    requestParameters: IntegrationsApiDeleteCloudinaryTransformationOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CloudinaryNamedTransformationResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/delete-cloudinary-transformation`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeleteCloudinaryTransformationRequestToJSON(requestParameters.delete_cloudinary_transformation_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CloudinaryNamedTransformationResponseFromJSON(jsonValue));
  }

  /**
   */
  async deleteCloudinaryTransformation(
    delete_cloudinary_transformation_request?: DeleteCloudinaryTransformationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CloudinaryNamedTransformationResponse> {
    const response = await this.deleteCloudinaryTransformationRaw(
      { delete_cloudinary_transformation_request: delete_cloudinary_transformation_request },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async exportAssetToCloudinaryRaw(
    requestParameters: IntegrationsApiExportAssetToCloudinaryOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CloudinaryUploadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/export-asset-to-cloudinary`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ExportAssetToCloudinaryRequestToJSON(requestParameters.export_asset_to_cloudinary_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CloudinaryUploadResponseFromJSON(jsonValue));
  }

  /**
   */
  async exportAssetToCloudinary(
    export_asset_to_cloudinary_request?: ExportAssetToCloudinaryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CloudinaryUploadResponse> {
    const response = await this.exportAssetToCloudinaryRaw({ export_asset_to_cloudinary_request: export_asset_to_cloudinary_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async exportBlobToCloudinaryRaw(
    requestParameters: IntegrationsApiExportBlobToCloudinaryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CloudinaryUploadResponse>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling exportBlobToCloudinary.');
    }

    if (requestParameters.cld_public_id === null || requestParameters.cld_public_id === undefined) {
      throw new runtime.RequiredError(
        'cld_public_id',
        'Required parameter requestParameters.cld_public_id was null or undefined when calling exportBlobToCloudinary.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.cld_related_id !== undefined) {
      formParams.append('cld_related_id', requestParameters.cld_related_id as any);
    }

    if (requestParameters.cld_display_name !== undefined) {
      formParams.append('cld_display_name', requestParameters.cld_display_name as any);
    }

    if (requestParameters.cld_public_id !== undefined) {
      formParams.append('cld_public_id', requestParameters.cld_public_id as any);
    }

    const response = await this.request(
      {
        path: `/integrations/cloudinary/export-blob-to-cloudinary`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CloudinaryUploadResponseFromJSON(jsonValue));
  }

  /**
   */
  async exportBlobToCloudinary(
    image: Blob,
    cld_public_id: string,
    cld_related_id?: string,
    cld_display_name?: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CloudinaryUploadResponse> {
    const response = await this.exportBlobToCloudinaryRaw(
      { image: image, cld_public_id: cld_public_id, cld_related_id: cld_related_id, cld_display_name: cld_display_name },
      initOverrides,
    );
    return await response.value();
  }
}
