// @mui

import { GlobalStyles as MUIGlobalStyles, useTheme } from '@mui/material';

// ----------------------------------------------------------------------
import { FocusStyleManager } from '@blueprintjs/core';

export default function GlobalStyles() {
  const theme = useTheme();
  FocusStyleManager.onlyShowFocusOnTabs();
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
          overscrollBehavior: 'none',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },

        // polotno customizations
        '.editor-container': {
          fontFamily: theme.typography.fontFamily,
          'button.bp5-button': {
            borderRadius: '8px',
          },
          '.bp5-input-group': {
            input: {
              borderRadius: '8px',
            },
          },
          '.bp5-card': {
            borderRadius: '8px',
          },
          '.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator': {
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            backgroundColor: theme.palette.primary.main,
          },
          '.bp5-control.bp5-switch .bp5-control-indicator::before': {
            boxShadow: 'none',
          },

          'button.bp5-button.bp5-intent-primary': {
            backgroundColor: theme.palette.primary.main,
            border: '0px solid white',
            'box-shadow': 'none',
          },
          'button.bp5-button.bp5-intent-primary.bp5-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
          },
          '.polotno-toolbar': {
            // boxShadow: '0px 1px 0px 1px rgba(0, 0, 0, 0.05)',

            height: '64px',
            padding: '7px 0px 0px 7px',
            boxShadow: '0px 2px 16px 0px #00000014',
            zIndex: 11,
            borderBottom: '1px solid #E2E6E2',
          },
          '.editor-toolbar > div': {
            backgroundColor: '#fff',
            color: '#212B36',

            // '-webkit-transition': 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            // transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            // boxShadow: '0px 6px 4px -1px rgba(0, 0, 0, 0.2)',
          },
          '.polotno-side-panel': {
            // boxShadow: '6px 0px 4px -1px rgba(0, 0, 0, 0.2)',
            border: '0px solid white',

            '.polotno-side-tabs-container': {
              boxShadow: '0px 4px 16px 0px #00000026',
              zIndex: 11,
            },
            '.polotno-side-tabs-inner': {
              paddingTop: '8px',
            },
          },

          '.zoom-wrapper': {
            height: 0,
            '& > div:nth-child(1) > div': {
              position: 'absolute',
              zIndex: 10,
              height: '35px',
              right: '150px',
              top: '20px',
              background: 'white',
              paddingRight: '0px !important',
              borderRadius: '8px',
              border: '1px solid #E2E6E2',
              boxSizing: 'content-box',
              boxShadow: '0 0 4px lightgrey',

              '& button': {
                height: '35px',
                width: '35px',
                boxSizing: 'border-box',
              },
            },
            '& > div:nth-child(2) > div': {
              left: 'unset',
              bottom: 'unset',
              transform: 'unset',
              top: '20px',
              right: '20px',
              zIndex: 10,
              border: '1px solid #E2E6E2',
            },
          },
          '*': {
            fontFamily: theme.typography.fontFamily,
          },
          '.polotno-side-panel-tab': {
            padding: '0',
            height: '56px',
            width: '72px',
            borderRadius: '8px',
            margin: '5px 8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            '& > div:first-child ': {
              '& > svg': {
                maxWidth: '20px',
                maxHeight: '20px',
              },

              '& + div': {
                // this style is set in the js text, need !important to override
                paddingTop: '0 !important',
                textWrap: 'initial',
              },
            },

            '&.active': {
              backgroundColor: '#fdecf4',
              color: theme.palette.primary.main,
            },
            '&:hover': {
              backgroundColor: '#fdecf4',
              color: theme.palette.primary.main,
            },
          },
          '.bp5-tab-list': {
            '.bp5-tab-indicator-wrapper': {
              '~ .bp5-tab[aria-selected=true]': {
                backgroundColor: 'blue',

                color: theme.palette.primary.main,
                boxShadow: `0px 0px -3px 0px ${theme.palette.primary.main}`,
              },
            },
            'tap-highlight-color': 'red',
          },
          '.editor-promoted-tool > svg': {
            color: theme.palette.primary.main,
          },
        },

        // utility class used to show child on parent hover
        '.hoverTarget .visibleOnHover': {
          visibility: 'hidden',
          opacity: 0,
          transition: 'visibility 0s, opacity 0.2s linear',
        },
        '.hoverTarget:hover .visibleOnHover, .hoverTarget.selected .visibleOnHover': {
          visibility: 'visible',
          opacity: 1,
        },
        '.konvajs-content > canvas': {
          pointerEvents: 'none',
        },
      }}
    />
  );

  return inputGlobalStyles;
}
