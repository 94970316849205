/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateAssetCollaboratorRequest
 */
export interface UpdateAssetCollaboratorRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateAssetCollaboratorRequest
   */
  role: UpdateAssetCollaboratorRequestRoleEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateAssetCollaboratorRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UpdateAssetCollaboratorRequest
   */
  asset_id: string;
}

/**
 * @export
 */
export const UpdateAssetCollaboratorRequestRoleEnum = {
  Viewer: 'viewer',
  Editor: 'editor',
} as const;
export type UpdateAssetCollaboratorRequestRoleEnum = (typeof UpdateAssetCollaboratorRequestRoleEnum)[keyof typeof UpdateAssetCollaboratorRequestRoleEnum];

/**
 * Check if a given object implements the UpdateAssetCollaboratorRequest interface.
 */
export function instanceOfUpdateAssetCollaboratorRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'role' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'asset_id' in value;

  return isInstance;
}

export function UpdateAssetCollaboratorRequestFromJSON(json: any): UpdateAssetCollaboratorRequest {
  return UpdateAssetCollaboratorRequestFromJSONTyped(json, false);
}

export function UpdateAssetCollaboratorRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAssetCollaboratorRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    role: json['role'],
    email: json['email'],
    asset_id: json['asset_id'],
  };
}

export function UpdateAssetCollaboratorRequestToJSON(value?: UpdateAssetCollaboratorRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    role: value.role,
    email: value.email,
    asset_id: value.asset_id,
  };
}
