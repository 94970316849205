/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CloudinaryAssetInfo
 */
export interface CloudinaryAssetInfo {
  /**
   *
   * @type {string}
   * @memberof CloudinaryAssetInfo
   */
  id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CloudinaryAssetInfo
   */
  named_transformations: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CloudinaryAssetInfo
   */
  named_transformations_base_layer: string;
}

/**
 * Check if a given object implements the CloudinaryAssetInfo interface.
 */
export function instanceOfCloudinaryAssetInfo(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'named_transformations' in value;
  isInstance = isInstance && 'named_transformations_base_layer' in value;

  return isInstance;
}

export function CloudinaryAssetInfoFromJSON(json: any): CloudinaryAssetInfo {
  return CloudinaryAssetInfoFromJSONTyped(json, false);
}

export function CloudinaryAssetInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudinaryAssetInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    named_transformations: json['named_transformations'],
    named_transformations_base_layer: json['named_transformations_base_layer'],
  };
}

export function CloudinaryAssetInfoToJSON(value?: CloudinaryAssetInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    named_transformations: value.named_transformations,
    named_transformations_base_layer: value.named_transformations_base_layer,
  };
}
