import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { UserSettings } from 'src/generated/app_server_sdk';
import { useAuthContext } from './useAuthContext';

type FeatureGrateProps = {
  flag: keyof UserSettings;
  value: string;
  navigateOnError: string;
};
export function NavigateionFeatureGate(props: FeatureGrateProps) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  useEffect(() => {
    if (user && user.settings && user.settings[props.flag] !== props.value) {
      navigate(props.navigateOnError);
    }
  }, [user, props, navigate]);
  return <Outlet />;
}

type RenderingFeatureGateProps = {
  flag: keyof UserSettings;
  value: string;
  children: React.ReactNode;
};

export function RenderingFeatureGate(props: RenderingFeatureGateProps) {
  const { user } = useAuthContext();
  if (user && user.settings && user.settings[props.flag] !== props.value) {
    return <></>;
  }
  return <>{props.children}</>;
}

interface OptionalFeatureGateProps extends Omit<RenderingFeatureGateProps, 'flag'> {
  flag: keyof UserSettings | undefined;
}
export function OptionalFeatureGate(props: OptionalFeatureGateProps) {
  if (typeof props.flag === 'undefined') {
    return <>{props.children}</>;
  }

  return (
    <RenderingFeatureGate flag={props.flag} value={props.value}>
      {props.children}
    </RenderingFeatureGate>
  );
}
