/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import { AssetFromJSON, AssetFromJSONTyped, AssetToJSON } from './Asset';

/**
 *
 * @export
 * @interface Collaborator
 */
export interface Collaborator {
  /**
   *
   * @type {string}
   * @memberof Collaborator
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Collaborator
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof Collaborator
   */
  role: CollaboratorRoleEnum;
  /**
   *
   * @type {string}
   * @memberof Collaborator
   */
  avatar_url?: string;
  /**
   *
   * @type {string}
   * @memberof Collaborator
   */
  collaborator_id?: string;
  /**
   *
   * @type {string}
   * @memberof Collaborator
   */
  name?: string;
  /**
   *
   * @type {Asset}
   * @memberof Collaborator
   */
  asset: Asset;
}

/**
 * @export
 */
export const CollaboratorRoleEnum = {
  Viewer: 'viewer',
  Editor: 'editor',
} as const;
export type CollaboratorRoleEnum = (typeof CollaboratorRoleEnum)[keyof typeof CollaboratorRoleEnum];

/**
 * Check if a given object implements the Collaborator interface.
 */
export function instanceOfCollaborator(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'role' in value;
  isInstance = isInstance && 'asset' in value;

  return isInstance;
}

export function CollaboratorFromJSON(json: any): Collaborator {
  return CollaboratorFromJSONTyped(json, false);
}

export function CollaboratorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Collaborator {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    email: json['email'],
    role: json['role'],
    avatar_url: !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
    collaborator_id: !exists(json, 'collaborator_id') ? undefined : json['collaborator_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    asset: AssetFromJSON(json['asset']),
  };
}

export function CollaboratorToJSON(value?: Collaborator | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    email: value.email,
    role: value.role,
    avatar_url: value.avatar_url,
    collaborator_id: value.collaborator_id,
    name: value.name,
    asset: AssetToJSON(value.asset),
  };
}
