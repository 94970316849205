/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloudinaryAssetInfo } from './CloudinaryAssetInfo';
import { CloudinaryAssetInfoFromJSON, CloudinaryAssetInfoFromJSONTyped, CloudinaryAssetInfoToJSON } from './CloudinaryAssetInfo';

/**
 *
 * @export
 * @interface AssetIntegrations
 */
export interface AssetIntegrations {
  /**
   *
   * @type {CloudinaryAssetInfo}
   * @memberof AssetIntegrations
   */
  cloudinary?: CloudinaryAssetInfo;
}

/**
 * Check if a given object implements the AssetIntegrations interface.
 */
export function instanceOfAssetIntegrations(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AssetIntegrationsFromJSON(json: any): AssetIntegrations {
  return AssetIntegrationsFromJSONTyped(json, false);
}

export function AssetIntegrationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetIntegrations {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cloudinary: !exists(json, 'cloudinary') ? undefined : CloudinaryAssetInfoFromJSON(json['cloudinary']),
  };
}

export function AssetIntegrationsToJSON(value?: AssetIntegrations | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cloudinary: CloudinaryAssetInfoToJSON(value.cloudinary),
  };
}
