/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ContentAnalysisResponse
 */
export interface ContentAnalysisResponse {
  /**
   *
   * @type {string}
   * @memberof ContentAnalysisResponse
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof ContentAnalysisResponse
   */
  category: string;
  /**
   *
   * @type {boolean}
   * @memberof ContentAnalysisResponse
   */
  withModel: boolean;
  /**
   *
   * @type {number}
   * @memberof ContentAnalysisResponse
   */
  subjectHeight: number;
  /**
   *
   * @type {number}
   * @memberof ContentAnalysisResponse
   */
  subjectWidth: number;
  /**
   *
   * @type {number}
   * @memberof ContentAnalysisResponse
   */
  subjectLength: number;
  /**
   *
   * @type {string}
   * @memberof ContentAnalysisResponse
   */
  make: string;
  /**
   *
   * @type {string}
   * @memberof ContentAnalysisResponse
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ContentAnalysisResponse
   */
  brand: string;
  /**
   *
   * @type {string}
   * @memberof ContentAnalysisResponse
   */
  colors: string;
  /**
   *
   * @type {string}
   * @memberof ContentAnalysisResponse
   */
  placement: string;
  /**
   *
   * @type {boolean}
   * @memberof ContentAnalysisResponse
   */
  NSFW: boolean;
  /**
   *
   * @type {string}
   * @memberof ContentAnalysisResponse
   */
  placementType: ContentAnalysisResponsePlacementTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ContentAnalysisResponse
   */
  amount: number;
}

/**
 * @export
 */
export const ContentAnalysisResponsePlacementTypeEnum = {
  Hung: 'hung',
  Placed: 'placed',
} as const;
export type ContentAnalysisResponsePlacementTypeEnum = (typeof ContentAnalysisResponsePlacementTypeEnum)[keyof typeof ContentAnalysisResponsePlacementTypeEnum];

/**
 * Check if a given object implements the ContentAnalysisResponse interface.
 */
export function instanceOfContentAnalysisResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'subject' in value;
  isInstance = isInstance && 'category' in value;
  isInstance = isInstance && 'withModel' in value;
  isInstance = isInstance && 'subjectHeight' in value;
  isInstance = isInstance && 'subjectWidth' in value;
  isInstance = isInstance && 'subjectLength' in value;
  isInstance = isInstance && 'make' in value;
  isInstance = isInstance && 'type' in value;
  isInstance = isInstance && 'brand' in value;
  isInstance = isInstance && 'colors' in value;
  isInstance = isInstance && 'placement' in value;
  isInstance = isInstance && 'NSFW' in value;
  isInstance = isInstance && 'placementType' in value;
  isInstance = isInstance && 'amount' in value;

  return isInstance;
}

export function ContentAnalysisResponseFromJSON(json: any): ContentAnalysisResponse {
  return ContentAnalysisResponseFromJSONTyped(json, false);
}

export function ContentAnalysisResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentAnalysisResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    subject: json['subject'],
    category: json['category'],
    withModel: json['withModel'],
    subjectHeight: json['subjectHeight'],
    subjectWidth: json['subjectWidth'],
    subjectLength: json['subjectLength'],
    make: json['make'],
    type: json['type'],
    brand: json['brand'],
    colors: json['colors'],
    placement: json['placement'],
    NSFW: json['NSFW'],
    placementType: json['placementType'],
    amount: json['amount'],
  };
}

export function ContentAnalysisResponseToJSON(value?: ContentAnalysisResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    subject: value.subject,
    category: value.category,
    withModel: value.withModel,
    subjectHeight: value.subjectHeight,
    subjectWidth: value.subjectWidth,
    subjectLength: value.subjectLength,
    make: value.make,
    type: value.type,
    brand: value.brand,
    colors: value.colors,
    placement: value.placement,
    NSFW: value.NSFW,
    placementType: value.placementType,
    amount: value.amount,
  };
}
