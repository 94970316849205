/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Collection } from './Collection';
import { CollectionFromJSON, CollectionFromJSONTyped, CollectionToJSON } from './Collection';

/**
 *
 * @export
 * @interface ListCollectionsResponse
 */
export interface ListCollectionsResponse {
  /**
   *
   * @type {Array<Collection>}
   * @memberof ListCollectionsResponse
   */
  items: Array<Collection>;
}

/**
 * Check if a given object implements the ListCollectionsResponse interface.
 */
export function instanceOfListCollectionsResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'items' in value;

  return isInstance;
}

export function ListCollectionsResponseFromJSON(json: any): ListCollectionsResponse {
  return ListCollectionsResponseFromJSONTyped(json, false);
}

export function ListCollectionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCollectionsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(CollectionFromJSON),
  };
}

export function ListCollectionsResponseToJSON(value?: ListCollectionsResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(CollectionToJSON),
  };
}
