/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FTCanvasChildrenInner } from './FTCanvasChildrenInner';
import { FTCanvasChildrenInnerFromJSON, FTCanvasChildrenInnerFromJSONTyped, FTCanvasChildrenInnerToJSON } from './FTCanvasChildrenInner';

/**
 *
 * @export
 * @interface FTCanvas
 */
export interface FTCanvas {
  /**
   *
   * @type {string}
   * @memberof FTCanvas
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof FTCanvas
   */
  width: number;
  /**
   *
   * @type {number}
   * @memberof FTCanvas
   */
  height: number;
  /**
   *
   * @type {string}
   * @memberof FTCanvas
   */
  background: string;
  /**
   *
   * @type {number}
   * @memberof FTCanvas
   */
  bleed: number;
  /**
   *
   * @type {number}
   * @memberof FTCanvas
   */
  duration: number;
  /**
   *
   * @type {Array<FTCanvasChildrenInner>}
   * @memberof FTCanvas
   */
  children: Array<FTCanvasChildrenInner>;
}

/**
 * Check if a given object implements the FTCanvas interface.
 */
export function instanceOfFTCanvas(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'width' in value;
  isInstance = isInstance && 'height' in value;
  isInstance = isInstance && 'background' in value;
  isInstance = isInstance && 'bleed' in value;
  isInstance = isInstance && 'duration' in value;
  isInstance = isInstance && 'children' in value;

  return isInstance;
}

export function FTCanvasFromJSON(json: any): FTCanvas {
  return FTCanvasFromJSONTyped(json, false);
}

export function FTCanvasFromJSONTyped(json: any, ignoreDiscriminator: boolean): FTCanvas {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    width: json['width'],
    height: json['height'],
    background: json['background'],
    bleed: json['bleed'],
    duration: json['duration'],
    children: (json['children'] as Array<any>).map(FTCanvasChildrenInnerFromJSON),
  };
}

export function FTCanvasToJSON(value?: FTCanvas | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    width: value.width,
    height: value.height,
    background: value.background,
    bleed: value.bleed,
    duration: value.duration,
    children: (value.children as Array<any>).map(FTCanvasChildrenInnerToJSON),
  };
}
