/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import { AssetFromJSON, AssetFromJSONTyped, AssetToJSON } from './Asset';

/**
 *
 * @export
 * @interface Lora
 */
export interface Lora {
  /**
   *
   * @type {string}
   * @memberof Lora
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Lora
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Lora
   */
  concept: string;
  /**
   *
   * @type {string}
   * @memberof Lora
   */
  common_features: string;
  /**
   *
   * @type {string}
   * @memberof Lora
   */
  status: LoraStatusEnum;
  /**
   *
   * @type {Date}
   * @memberof Lora
   */
  created_at: Date;
  /**
   *
   * @type {Date}
   * @memberof Lora
   */
  updated_at: Date;
  /**
   *
   * @type {Array<Asset>}
   * @memberof Lora
   */
  images: Array<Asset>;
}

/**
 * @export
 */
export const LoraStatusEnum = {
  Active: 'active',
  Pending: 'pending',
  Disabled: 'disabled',
  Hidden: 'hidden',
  Processing: 'processing',
  Failed: 'failed',
} as const;
export type LoraStatusEnum = (typeof LoraStatusEnum)[keyof typeof LoraStatusEnum];

/**
 * Check if a given object implements the Lora interface.
 */
export function instanceOfLora(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'concept' in value;
  isInstance = isInstance && 'common_features' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'updated_at' in value;
  isInstance = isInstance && 'images' in value;

  return isInstance;
}

export function LoraFromJSON(json: any): Lora {
  return LoraFromJSONTyped(json, false);
}

export function LoraFromJSONTyped(json: any, ignoreDiscriminator: boolean): Lora {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    concept: json['concept'],
    common_features: json['common_features'],
    status: json['status'],
    created_at: new Date(json['created_at']),
    updated_at: new Date(json['updated_at']),
    images: (json['images'] as Array<any>).map(AssetFromJSON),
  };
}

export function LoraToJSON(value?: Lora | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    concept: value.concept,
    common_features: value.common_features,
    status: value.status,
    created_at: value.created_at.toISOString(),
    updated_at: value.updated_at.toISOString(),
    images: (value.images as Array<any>).map(AssetToJSON),
  };
}
