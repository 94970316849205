/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FTImageElement, instanceOfFTImageElement, FTImageElementFromJSON, FTImageElementFromJSONTyped, FTImageElementToJSON } from './FTImageElement';
import { FTShapeElement, instanceOfFTShapeElement, FTShapeElementFromJSON, FTShapeElementFromJSONTyped, FTShapeElementToJSON } from './FTShapeElement';
import { FTTextElement, instanceOfFTTextElement, FTTextElementFromJSON, FTTextElementFromJSONTyped, FTTextElementToJSON } from './FTTextElement';

/**
 * @type FTCanvasChildrenInner
 *
 * @export
 */
export type FTCanvasChildrenInner = FTImageElement | FTShapeElement | FTTextElement;

export function FTCanvasChildrenInnerFromJSON(json: any): FTCanvasChildrenInner {
  return FTCanvasChildrenInnerFromJSONTyped(json, false);
}

export function FTCanvasChildrenInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): FTCanvasChildrenInner {
  if (json === undefined || json === null) {
    return json;
  }
  return { ...FTImageElementFromJSONTyped(json, true), ...FTShapeElementFromJSONTyped(json, true), ...FTTextElementFromJSONTyped(json, true) };
}

export function FTCanvasChildrenInnerToJSON(value?: FTCanvasChildrenInner | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }

  if (instanceOfFTImageElement(value)) {
    return FTImageElementToJSON(value as FTImageElement);
  }
  if (instanceOfFTShapeElement(value)) {
    return FTShapeElementToJSON(value as FTShapeElement);
  }
  if (instanceOfFTTextElement(value)) {
    return FTTextElementToJSON(value as FTTextElement);
  }

  return {};
}
