/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FindOneAssetByRequestParams
 */
export interface FindOneAssetByRequestParams {
  /**
   *
   * @type {string}
   * @memberof FindOneAssetByRequestParams
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FindOneAssetByRequestParams
   */
  checksum?: string;
  /**
   *
   * @type {number}
   * @memberof FindOneAssetByRequestParams
   */
  bytes?: number;
  /**
   *
   * @type {string}
   * @memberof FindOneAssetByRequestParams
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FindOneAssetByRequestParams
   */
  origin?: FindOneAssetByRequestParamsOriginEnum;
  /**
   *
   * @type {string}
   * @memberof FindOneAssetByRequestParams
   */
  integration?: string;
  /**
   *
   * @type {string}
   * @memberof FindOneAssetByRequestParams
   */
  external_id?: string;
}

/**
 * @export
 */
export const FindOneAssetByRequestParamsOriginEnum = {
  GenerativePackshot: 'generative_packshot',
  UserProduct: 'user_product',
  GeneratedProp: 'generated_prop',
  TextToGraphicDesign: 'text_to_graphic_design',
  TextToImage: 'text_to_image',
  VirtualStaging: 'virtual_staging',
  RestyleImage: 'restyle_image',
  BlendTwoImages: 'blend_two_images',
  Inpaint: 'inpaint',
  StudioShot: 'studio_shot',
  HomeStaging: 'home_staging',
  Editor: 'editor',
  LoraInputImage: 'lora_input_image',
  LoraTextToImage: 'lora_text_to_image',
  UserImage: 'user_image',
  Template: 'template',
} as const;
export type FindOneAssetByRequestParamsOriginEnum = (typeof FindOneAssetByRequestParamsOriginEnum)[keyof typeof FindOneAssetByRequestParamsOriginEnum];

/**
 * Check if a given object implements the FindOneAssetByRequestParams interface.
 */
export function instanceOfFindOneAssetByRequestParams(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function FindOneAssetByRequestParamsFromJSON(json: any): FindOneAssetByRequestParams {
  return FindOneAssetByRequestParamsFromJSONTyped(json, false);
}

export function FindOneAssetByRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOneAssetByRequestParams {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    checksum: !exists(json, 'checksum') ? undefined : json['checksum'],
    bytes: !exists(json, 'bytes') ? undefined : json['bytes'],
    name: !exists(json, 'name') ? undefined : json['name'],
    origin: !exists(json, 'origin') ? undefined : json['origin'],
    integration: !exists(json, 'integration') ? undefined : json['integration'],
    external_id: !exists(json, 'external_id') ? undefined : json['external_id'],
  };
}

export function FindOneAssetByRequestParamsToJSON(value?: FindOneAssetByRequestParams | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    checksum: value.checksum,
    bytes: value.bytes,
    name: value.name,
    origin: value.origin,
    integration: value.integration,
    external_id: value.external_id,
  };
}
